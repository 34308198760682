ï»¿const $ = require("jquery");
const saveAs = require("saveas");
const streamSaver = require("streamsaver");
const culture = require("../Globalization/plex-culture-datetime");
const plexExport = require("../../global-export");
const environment = require("../Core/plex-env");
const Glossary = require("../Globalization/plex-glossary-handler");

const FEATURE_FLAG = "feat-tri-4751-streamsaver-progress-bar";

const exporter = {};
// square brackets [] cause an issue with excel pivot tables
const illegalCharsRgx = /[/\\?%*:<>[\]]/g;
exporter.glossary = {};

function generateFilename(filename) {
  filename = (filename || document.title) + "-" + culture.formatDate(new Date(), "S");
  return filename.replace(illegalCharsRgx, "");
}

const streamWriter = (data, serializer, filename, progressBar) => {
  progressBar.setLimit(data.length);

  let progress = 0;
  const progressCallback = (alldata, serialized) => {
    const percentage = Math.round((serialized.length / alldata.length) * 100);
    if (percentage % 10 === 0 && progress < percentage) {
      progress = percentage;
      progressBar.incrementProgress(serialized.length, true);
      progressBar.setCustomProgressMessage(exporter.glossary["{1}% complete"].replace("{1}", progress));
    }
  };

  const content = serializer.serializeToBlob(data, progressCallback);
  return content
    .then((result) => {
      const fileStream = streamSaver.createWriteStream(generateFilename(filename) + serializer.extension, {
        size: result.size // Makes the percentage visiable in the download
      });

      // One quick alternative way if you don't want the whole blob.js thing:
      // const readableStream = new Response(
      //   Blob || String || ArrayBuffer || ArrayBufferView
      // ).body
      const readableStream = result.stream();

      // Write (pipe) manually
      const writer = fileStream.getWriter();

      const reader = readableStream.getReader();

      const pump = () => {
        reader
          .read()
          .then((res) => {
            if (res.done) {
              progressBar.hide();
              writer.close();
            } else {
              progressBar.incrementProgress(1);
              writer.write(res.value).then(pump);
            }
          })
          .catch(() => {
            progressBar.hide();
            writer.close();
          });
      };

      pump();
    })
    .catch(() => {
      progressBar.hide();
    });
};

if (saveAs) {
  exporter.saveAs = function (data, serializer, filename, progressBar) {
    if (environment.features[FEATURE_FLAG]) {
      return streamWriter(data, serializer, filename, progressBar);
    } else {
      $(document).block({ delay: 5 });

      const progressCallback = () => {};
      const content = serializer.serializeToBlob(data, progressCallback);

      return content
        .then((result) => {
          saveAs(result, generateFilename(filename) + serializer.extension);
        })
        .then(
          () => $(document).unblock(),
          () => $(document).unblock()
        );
    }
  };
} else {
  // IE9 fallback
  exporter.saveAs = function (data, serializer, filename, progressBar) {
    if (environment.features[FEATURE_FLAG]) {
      return streamWriter(data, serializer, filename, progressBar);
    } else {
      $(document).block({ delay: 5 });
      const content = serializer.serialize(data);

      return content.then((result) => {
        // IE9 has restrictions on extensions
        const ext = serializer.altExtension || serializer.extension;

        const dummy = document.createElement("iframe");
        dummy.style.display = "none";
        document.body.appendChild(dummy);
        const doc = dummy.contentWindow.document;
        doc.open(serializer.mimeType);
        doc.charset = "utf-8";
        doc.write(result);
        doc.execCommand("SaveAs", true, generateFilename(filename) + ext);
        doc.close();

        setTimeout(() => {
          document.body.removeChild(dummy);
        }, 500);
        $(document).unblock();
      });
    }
  };
}

exporter.setGlossarizedMessages = function () {
  exporter.getGlossarizedMessage("Exporting...");
  exporter.getGlossarizedMessage("{1}% complete");
};

exporter.getGlossarizedMessage = function (message) {
  if (exporter.glossary[message]) {
    //
  } else {
    Glossary.getCustomerWordAsync(message).done((glossarizesMessage) => {
      exporter.glossary[message] = glossarizesMessage;
    });
  }
};

module.exports = exporter;
plexExport("exporter", exporter);
