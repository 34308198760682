ï»¿const $ = require("jquery");
const ko = require("knockout");
const jsUtils = require("../../../Utilities/plex-utils-js");
const DocumentXml = require("../../../Utilities/plex-utils-documentxml");
const FeatureResult = require("../../../Features/plex-feature-result");
const plexExport = require("../../../../global-export");

const cssMap = {
  // css print attributes map
  "plex-grid-cell-suppressed": {
    suppressed: true
  },
  "plex-grid-column-group": {
    "text-align": "center"
  },
  "plex-grid-column-group-border-left": {
    "border-left": "0.5pt solid #ddd"
  },
  "plex-grid-column-group-border-right": {
    "border-right": "0.5pt solid #ddd"
  },
  "plex-int-text": {
    "text-align": "right"
  },
  "plex-numeric-text": {
    "text-align": "right"
  },
  "plex-date-text": {
    "text-align": "right"
  },
  "plex-boolean": {
    "text-align": "center",
    "padding-top": "3pt"
  },
  "plex-image": {
    "text-align": "center",
    "padding-top": "3pt"
  },
  "plex-grid-cell-align-right": {
    "text-align": "right"
  },
  "plex-grid-cell-align-center": {
    "text-align": "center"
  }
};

const PrintWriter = function () {
  // constructor
};

PrintWriter.prototype = {
  constructor: PrintWriter,

  init: function (grid) {
    this.grid = grid;
    if (this.getNodeName()) {
      this.node = new DocumentXml(this.getNodeName());
    }
  },

  getNodeName: function () {
    // must be overridden by extenders
    return "";
  },

  prePrint: function (record, index, data) {
    // re-init node
    if (this.node) {
      this.node = new DocumentXml(this.getNodeName());
    }

    // do not apply features if data is empty
    if (!this.featureProcessor || data.length === 0) {
      this.features = new FeatureResult();
      return true;
    }

    this.features = this.featureProcessor.process.apply(this.featureProcessor, arguments);
    return this.features.render !== false && this.grid.options.visible() && ko.unwrap(this.grid.options.printVisible);
  },

  print: function () {
    // should be overwritten by inheritors
  },

  writeFeatures: function (node, features) {
    this.writeCss(node, features.css);
    this.writeAttributes(node, features.attr);
    this.writeStyle(node, features.style);
  },

  writeCss: function (node, cssClasses) {
    if (node && cssClasses && cssClasses.length > 0) {
      cssClasses.forEach((css) => {
        if (css in cssMap) {
          $.each(cssMap[css], (key, value) => {
            node.addAttribute(key, value);
          });
        }
      });
    }
  },

  writeAttributes: function (node, attr) {
    if (!node || !attr || $.isEmptyObject(attr)) {
      return;
    }

    $.each(attr, (prop, value) => {
      node.addAttribute(prop, value);
    });
  },

  writeStyle: function (node, style) {
    if (!node || !style || $.isEmptyObject(style)) {
      return;
    }

    $.each(style, (prop, value) => {
      node.addAttribute(prop, value);
    });
  },

  toPrintXml: function () {
    return this.node ? this.node.serialize() : "";
  }
};

jsUtils.makeExtendable(PrintWriter);

module.exports = PrintWriter;
plexExport("grid.writers.PrintWriter", PrintWriter);
